import { Controller } from 'stimulus';
import moment from 'moment';
import { Tooltip } from 'bootstrap';

export default class extends Controller {
  static values = { format: String, time: String, showRelative: Boolean };
  static targets = ['display'];

  connect() {
    let formattedTime = '';
    let tooltipContent = '';

    if(this.hasShowRelativeValue && this.showRelativeValue == true) {
      formattedTime = moment(this.timeValue).fromNow();
      tooltipContent = moment(this.timeValue).format(this.formatValue);
    } else {
      formattedTime = moment(this.timeValue).format(this.formatValue);
      tooltipContent = moment(this.timeValue).fromNow();
    }

    this.displayTarget.innerText = formattedTime;
    this.displayTarget.setAttribute('data-bs-toggle', 'tooltip');
    this.displayTarget.setAttribute('data-bs-placement', 'top');
    this.displayTarget.setAttribute('title', tooltipContent);

    // Initialize Bootstrap tooltip
    this.tooltip = new Tooltip(this.displayTarget);

    // Update tooltip content every minute if showing relative time
    if (this.showRelativeValue) {
      this.timer = setInterval(() => {
        this.tooltip.setContent({ '.tooltip-inner': moment(this.timeValue).fromNow() });
      }, 60000);
    }
  }

  disconnect() {
    if (this.timer) clearInterval(this.timer);
    if (this.tooltip) this.tooltip.dispose();
  }
}